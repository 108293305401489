<template>
  <div class="wrapper">
    <ks-header />
    <ks-heading-bio />
    <div class="ks-bio__col">
      <ks-bio-chapter title="Детство" timeline="1983 - 1995 гг.">
        <p class="ks-bio__bblock-text">
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Я родился и вырос в Восточном округе Москвы. Мои дедушка с бабушкой приехали в столицу в военный период: бабушка как
            боец ополчения, дедушка в качестве разнорабочего. Позже у них родилась моя мама.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Семья, в которой я рос, была обычной семьёй пролетариев того времени. Отца я совсем не помню, в возрасте пяти лет
            единственный раз говорил с ним по телефону. Мама часто была на работе, поэтому я рос с бабушкой, женщиной строгих
            правил. Благодаря её воспитанию 90-ые для меня прошли относительно спокойно.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            В школе я не был популярным. Мне было сложно общаться с остальными ребятами, тяжело находить с ними общий язык.
          </span>
        </p>
      </ks-bio-chapter>
      <ks-bio-photo img-url="block-1.jpg" alt="Группа Алексея в дестском саду" />
    </div>
    <div class="ks-bio__col">
      <ks-bio-chapter title="Юность" timeline="1995 - 2000 гг.">
        <p class="ks-bio__bblock-text">
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Зато мне было очень интересно разбираться в компьютерах:  уроки информатики, игры, первые программы. Я даже знал что
            такое «командная строка», и мог с её помощью запустить игру, что делало меня самым ценным человеком в компьютерном
            классе.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Но вот за что я действительно очень благодарен школе, так это за профориентацию. Они работали потрясающе эффективно,
            поэтому ещё в школьном возрасте я знал, что хочу работать менеджером, с людьми. Но сразу, естественно, ничего не
            бывает.
          </span>
        </p>
      </ks-bio-chapter>
    </div>
    <div class="ks-bio__col">
      <ks-bio-chapter title="Молодость" timeline="2000 - 2017 гг.">
        <p class="ks-bio__bblock-text">
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Поступив в университет МГУЭСИ (МЭСИ), на специальность «Менеджмент организации», специализация «Развитие
            человеческих ресурсов», я вспомнил своё увлечение информационными технологиями, поэтому быстро попал в Студенческий
            совет, где занял должность человека отвечающего за информационный блок. Организовывал мероприятия, проводил встречи.
            Однажды удалось на Windows NT установить CS 1.6 и устроить турнир среди факультетов.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            В 2000 году вышло дополнение к игре «Герои Меча и Магии 3». Скачать или купить возможности не было, поэтому я
            попросил у друга диск. Друг учился в РГСУ, встретились мы в Сокольниках, а с ним была его подруга. Мы друг другу
            понравились. Она пела в хоре РГСУ, ну, и я туда пошёл петь. С девушкой мы расстались, из хора она ушла. Зато в этом
            же хоре я познакомился со своей будущей женой — Ольгой. Через полгода я сделал ей предложение. Через 5 лет мы
            сыграли свадьбу. В 2010-ом году у нас родилась дочка, в 2013-ом — сын.
          </span>
          <br />
          <ks-bio-photo img-url="block-2.jpg" alt="Алексей с женой Ольгой" />
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            На первую работу я попал через знакомых, когда ещё учился на специалитете. Мне предложили участвовать в съёмках
            кино. Я был реквизитором на площадке, отвечал за все предметы в кадре — реквизит. Однажды даже заменял каскадёра.
            Платили $600 в месяц, на те времена это были большие деньги. Главным итогом этой работы стал фильм, где моя фамилия
            есть в титрах: «Неверность» (2006), в съемках которого я принимал участие от начала и до конца.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Когда заканчивал учёбу в университете меня отправили на практику в ВЗАО "АСЭН". Поскольку с кино в итоге не
            сложилось, место преддипломной практики и стало моей работой на следующие 5 лет. В этой компании я занимал должность
            начальника информационно-аналитического отдела, если говорить простыми словами — эникейщика. Следил за работой
            сервера, рассылкой почты, сетевым оборудованием и системным администратором, единственным сотрудником моего отдела. 
            Ну, а в свободное время мы играли в BattleField по сетке, я перепробовал все моды на The Elder Scrolls IV: Oblivion
            и так далее по списку.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Несмотря на то, что вроде бы работа была в большей степени связана с техникой, главное, что я вынес по прошествии
            пяти лет – это умение отстаивать свою точку зрения. В дальнейшем мне это очень сильно пригодилось.
          </span>
        </p>
      </ks-bio-chapter>
      <ks-bio-photo img-url="block-3.jpg" alt="Хор РГСУ" />
    </div>
    <div class="ks-bio__col">
      <ks-bio-chapter title="Зрелость" timeline="2017 - НАШИ ДНИ">
        <p class="ks-bio__bblock-text">
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            В 2017-ом году я участвовал в конкурсе «Молодой преподаватель». Победил в одной из компетенций. В числе приглашенных
            гостей в зале сидела Назарова Ольга Юрьевна, на то время действующий директор Колледжа РГСУ. После мероприятия она
            связалась со мной и предложила подумать о том, чтобы в будущем занять ее должность. Я был очень удивлён, даже
            немного испуган. Выдержал мхатовскую паузу и ответил: «Да». Ждать официальное предложение о работе оставалось 3
            года...
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            С того момента многое изменилось. Я чувствую ответственность за каждого преподавателя и студента. Каждое утро мне
            хочется идти на работу, каждый вечер – возвращаться домой, и это мне нравится.
          </span>
          <br />
          <br />
          <span data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            Я понял для себя одну простую истину – работа крупного менеджера не том, чтобы прийти и поменять всё, переделать,
            перестроить, а в том, чтобы поддерживать весь механизм работы. Здесь, в колледже, каждый человек на своём месте. Моя
            задача – обеспечить этому человеку всё для продуктивной и комфортной работы.
          </span>
        </p>
      </ks-bio-chapter>
      <ks-bio-photo img-url="main-bio-1-1.jpg" alt="Актовый зал РГСУ" />
    </div>
    <ks-footer />
  </div>
</template>

<script>
import KsHeader from '@/components/ks-header.vue'
import KsHeadingBio from '@/components/biography/ks-heading-bio.vue'
import KsBioChapter from '@/components/biography/ks-bio-chapter.vue'
import KsBioPhoto from '@/components/biography/ks-bio-photo.vue'
import KsFooter from '@/components/ks-footer.vue'
import AOS from 'aos'

export default {
  components: {
    KsHeader,
    KsHeadingBio,
    KsBioChapter,
    KsBioPhoto,
    KsFooter,
  },
  created() {
    AOS.init()
  },
}
</script>

<style>
.ks-bio__col {
  width: 100%;
  padding: 0 160px;
  margin-bottom: 16rem;
}
@media screen and (max-width: 800px) {
  .ks-bio__col {
    padding: 0 8px;
  }
}
</style>
